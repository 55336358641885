import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {tap, timeout} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {UtilService} from "./util.service";
import {LoadingService} from "./loading.service";

@Injectable()
export class ProfeshHttpInterceptor implements HttpInterceptor {

    constructor(private utilService: UtilService, private router: Router, private loadingService: LoadingService) {
    }

    // Intercept each http request globally
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.body.result === 'error') {
                    this.utilService.displayToast(`Error: ${event.body.error_description}`);
                    if (event.body.error_code === 404) this.router.navigateByUrl('404').then();
                }
            }
        }, (err: any) => {
            if (err.status === 403) {
                this.utilService.displayToast('You don\'t have permission to access this page');
            } else if (err.status === 500) {
                this.utilService.showConnectionErrorToast();
            }
            this.loadingService.finishLoading();
        }), timeout(30000));
    }
}
