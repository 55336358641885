import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  isProduction = environment.production;
  constructor(private snackbar: MatSnackBar) {
  }

  displayToast(message: string, action: string = 'Close', duration: number = 4000): void {
    this.snackbar.open(message, action, {
      duration,
      panelClass: "toast-dialog"
    });
  }

  showConnectionErrorToast() {
    this.displayToast('Something went wrong, Please try again later.');
  }
}
