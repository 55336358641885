import {Component, Injector, Input, Optional} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {
  RegisterEarlyAccessModalComponent
} from "../../../layout/components/register-early-access-modal/register-early-access-modal.component";
import {BaseComponent} from "../../../../@canguru/components/base/base.component";
import {EarlyAccessForm} from "../../interfaces/api/earlyAccess";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-early-access-form',
  templateUrl: './early-access-form.component.html',
  styleUrls: ['./early-access-form.component.scss']
})
export class EarlyAccessFormComponent extends BaseComponent {
  form: FormGroup;
  @Input() col = 'col-md-3 col-sm-12';
  @Input() showHover = true;
  @Input() openFromModal = false;

  constructor(injector: Injector, @Optional() public dialogRef: MatDialogRef<RegisterEarlyAccessModalComponent>) {
    super(injector);
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
    this.form.markAsPristine();
  }

  submit(value: EarlyAccessForm): void {
    this.loadingService.startLoading();
    this.earlyAccessService.register(value).subscribe({
      next: (res) => {
        if (res.result !== 'success') return;
        this.utilService.displayToast(this.translateService.instant('earlyAccessModal.success'))
        this.form.reset();
        if (this.dialogRef) this.dialogRef.close();
      }, error: (err) => {
        if (!environment.production) console.log(err);
      }
    }).add(() => {
      this.loadingService.finishLoading();
    })
  }

  goToPage(route: 'privacy' | 'terms'): void {
    if (this.dialogRef) this.dialogRef.close();
    this.router.navigateByUrl(route).then();
  }
}
