import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {BlogsResponse} from "../interfaces/api/blogs/blogs";
import {HttpService} from "./http.service";
import {api} from "../config/api";
import {BlogDetailsResponse} from "../interfaces/api/blogs/blogDetails";

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpService) { }

  getFeatured(): Observable<BlogsResponse> {
    return this.http.get(api.featuredBlogs);
  }
  getBlogs(): Observable<BlogsResponse> {
    return this.http.get(api.blogs);
  }
  getDetails(id: number): Observable<BlogDetailsResponse> {
    return this.http.get(api.blogDetails, {id});
  }
}
