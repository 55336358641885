import {Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {sideMenu} from "../../../../@canguru/static/sideMenu";
import {NavigationItem} from "../../../../@canguru/interfaces/navigation-item.interface";
import {BaseComponent} from "../../../../@canguru/components/base/base.component";
import {RegisterEarlyAccessModalComponent} from "../register-early-access-modal/register-early-access-modal.component";
import {scaleFadeIn400ms} from "../../../../@canguru/animations/scale-fade-in.animation";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [scaleFadeIn400ms]
})
export class HeaderComponent extends BaseComponent {
  isMenuOpen = false;
  sideMenuButtons = sideMenu;
  @ViewChild('measuringWrapper', {static: true}) measuringWrapper: ElementRef | undefined;
  @ViewChild('mobileMenu', {static: true}) mobileMenu: ElementRef | undefined;

  constructor(injector: Injector) {
    super(injector);
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.measuringWrapper && this.mobileMenu) {
      const height = this.measuringWrapper.nativeElement.clientHeight;
      this.mobileMenu.nativeElement.style.height = this.isMenuOpen ? `${height}px` : 0;
    }
  }

  goToPage(navigation: NavigationItem, fromMobile = false): void {
    if (fromMobile) this.toggleMenu();
    if (navigation.type === 'modal') {
      this.dialog.open(RegisterEarlyAccessModalComponent);
      return;
    }
    this.router.navigateByUrl(navigation.route).then();
  }

  goHome(): void {
    this.router.navigateByUrl('').then();
  }
}
