import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-register-early-access-modal',
  templateUrl: './register-early-access-modal.component.html',
  styleUrls: ['./register-early-access-modal.component.scss']
})
export class RegisterEarlyAccessModalComponent {
  constructor(private dialogRef: MatDialogRef<RegisterEarlyAccessModalComponent>) {
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
