import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register-for-early-access',
  templateUrl: './register-for-early-access.component.html',
  styleUrls: ['./register-for-early-access.component.scss']
})
export class RegisterForEarlyAccessComponent {

}
