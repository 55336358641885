import {Component, HostListener, Injector} from '@angular/core';
import {LoadingService} from "../shared/services/loading.service";
import {fadeInUp400ms} from "../../@canguru/animations/fade-in-up.animation";
import {BaseComponent} from "../../@canguru/components/base/base.component";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [fadeInUp400ms]
})
export class LayoutComponent extends BaseComponent {
  constructor(injector: Injector) {
    super(injector);
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: Event) {
    this.imageService.isMobile = window.innerWidth < 771;
  }
}
