import {environment} from "../../../environments/environment";

const baseUrl = environment.server;

export const api = {
  featuredBlogs: `${baseUrl}blogs?featured=1`,
  blogs: `${baseUrl}blogs`,
  blogDetails: `${baseUrl}blog`,
  registerEarlyAccess: `${baseUrl}registerEarlyAccess`
}
