import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from "./layout/layout.component";
import {ProfeshPreloadingStrategy} from "./shared/services/preload.strategy";

const routes: Routes = [{
  path: '',
  component: LayoutComponent,
  children: [{
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  }, {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
  }, {
    path: 'blog',
    loadChildren: () => import('./pages/blog-screens/blog-screens.module').then(m => m.BlogScreensModule),
    data: { preload: false }
  },  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
  },  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule),
  }, {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  }, {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  }]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: ProfeshPreloadingStrategy
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
