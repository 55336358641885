<form class="form-container flex-column align-items-center justify-content-center d-flex" [formGroup]="form"
      (ngSubmit)="form.valid && submit(form.value)">
  <mat-form-field [ngClass]="col" class="input-headline mt-2">
    <mat-label>{{'registerEarlyAccess.name' | translate}}</mat-label>
    <input type="email" matInput formControlName="name">
    <mat-error *ngIf="form.controls['name'].hasError('required') && form.controls['name'].dirty">
      Name is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field [ngClass]="col" class="input-headline mt-1">
    <mat-label>{{'registerEarlyAccess.email' | translate}}</mat-label>
    <input type="email" matInput formControlName="email">
    <mat-error *ngIf="form.controls['email'].hasError('email') && !form.controls['email'].hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="form.controls['email'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <div class="mt-1" [ngClass]="[col, showHover ? 'animated-arrow-container' : 'arrow-container']">
    <button [attr.aria-label]="'registerEarlyAccess.submit' | translate" class="arrow-link submit col-12  mb-1 mt-1 p-0" type="submit">
        <span class="the-arrow -left">
          <span class="shaft"></span>
        </span>
      <span class="main">
          <span class="text">
            {{'registerEarlyAccess.submit' | translate}}
          </span>
          <span class="the-arrow -right">
            <span class="shaft"></span>
          </span>
        </span>
    </button>
  </div>
  <p [ngClass]="col" class="agreement-text">{{'registerEarlyAccess.agree' | translate}}
    <strong class="link" (click)="goToPage('privacy')">{{'footer.privacy' | translate}}</strong> {{'registerEarlyAccess.and' | translate}}
    <strong class="link" (click)="goToPage('terms')">{{'footer.terms' | translate}}</strong></p>
</form>
